<template src="./FilterOrderForm.html"></template>
<style scoped lang="scss" src="./FilterOrderForm.scss"></style>

<script>
import {TypeMasterData} from "@/constants";
import { ServiceFactory } from "@/services";
const MasterManagementService = ServiceFactory.get('master')
export default {
  name: 'FilterOrderForm',
  props: {
    filterData: {},
    isAdmin: {
      require: false,
      type: Boolean,
      default: false
    },
    isLoading: {
      require: false,
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      brandOptions: {},
      categoryOptions: {},
      bodyOptions: {},
      orderFactoryOptions: {},
      importerOptions: {},
      contentOptions: {},
      classOptions: {},
      sizeOptions: {},
      colorOptions: {},
    }
  },
  created() {
    this.init()
  },
  methods: {
    clickToSearchButton() {
      this.$emit('clickToSearch', this.filterData)
    },
    clickToResetButton() {
      this.$emit('clickToReset')
    },
    async init() {
      if (this.isAdmin) {
        await Promise.all([
          this.getCategoryOptions(),
          this.getBodyOptions(),
          this.getBrandOptions(),
          this.getOrderFactoryOptions(),
          this.getImporterOptions(),
          this.getContentOptions(),
          this.getClassifyOptions(),
          this.getSizeOptions(),
          this.getColorOptions()
        ])
      }
    },
    async getCategoryOptions() {
      this.categoryOptions = await this.getOptionListItems(TypeMasterData.category)
    },

    async getBodyOptions() {
      this.bodyOptions = await this.getOptionListItems(TypeMasterData.body)
    },

    async getBrandOptions() {
      this.brandOptions = await this.getOptionListItems(TypeMasterData.brand)
    },

    async getOrderFactoryOptions() {
      this.orderFactoryOptions = await this.getOptionListItems(TypeMasterData.factory)
    },

    async getImporterOptions() {
      this.importerOptions = await this.getOptionListItems(TypeMasterData.importer)
    },

    async getContentOptions() {
      this.contentOptions = await this.getOptionListItems(TypeMasterData.problemContent)
    },

    async getClassifyOptions() {
      this.classOptions = await this.getOptionListItems(TypeMasterData.classify)
    },

    async getColorOptions() {
      this.colorOptions = await this.getOptionListItems(TypeMasterData.color)
    },

    async getSizeOptions() {
      this.sizeOptions = await this.getOptionListItems(TypeMasterData.size)
    },

    async getOptionListItems(type) {
      const items = await MasterManagementService.getItemsList({ type }).catch(() => ([]))
      return items || []
    }
  }
}
</script>
